<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >手机号码在线生成</h2>
		<el-row style="margin-top: 4%;">
		<el-col :sm="11" style="margin: 0 auto; text-align: center;">
			<el-form :model="phoneData">
				<el-form-item label="生成数量" >
						<el-col :sm = "24">
							<el-input v-model.number="phoneData.size" placeholder="请输入1-1000范围整数"></el-input>
						</el-col>
				</el-form-item>
				
				 <el-checkbox-group v-model="phoneData.operatorList">
						<el-checkbox  v-model="phoneData.removeSeparator" label="1">移动</el-checkbox>
						<el-checkbox  v-model="phoneData.removeSeparator" label="2">联通</el-checkbox>
						<el-checkbox  v-model="phoneData.removeSeparator" label="3">电信</el-checkbox>
				 </el-checkbox-group>
			
				<el-form-item label="">
					<el-col :sm = "4"></el-col>
					<el-input readonly  type="textarea" :rows="phoneData.rows" resize='none'  v-model="phoneData.text"></el-input>
				</el-form-item>
				<div style="margin: 0 auto; text-align: center;">
					<el-button id="1001" type="primary" @click="generate()">生成</el-button>
					<el-button  @click="copy(nameData.text)" type="primary" plain>复制</el-button>
				</div>
			</el-form>
	</el-col>
	</el-row>
</template>

<script setup>
import {ref} from 'vue'
import {generate as mobilephoneGenerate} from './mobilephone'
import {numberule as checknumber} from '../../common/validate'
import {moduleEvent as moduleaction} from '../../common/actionrecord'
import {clipboard as copy} from '../../common/actionrecord'
    let phoneData = ref({
		moduleid: 1003,
        size: 10,
        rows: 10,
		operatorList: ["1","2","3"],
        text: ""
		
    })
	moduleaction(phoneData.value.moduleid,1);
    let generate = () => {
		//校验代码
		var validresult = checknumber(phoneData.value.size,1000);
		if(!validresult.success){
			phoneData.value.text = validresult.info;
			return;
		}
		phoneData.value.text = mobilephoneGenerate(parseInt(phoneData.value.size)).join("\n");
		//模块事件 1.预览 2.使用
		moduleaction(phoneData.value.moduleid,2);
    }
//进入页面，触发数据生成逻辑
generate();	
</script>

<style>

</style>