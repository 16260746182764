let random = (lower, upper) => {
    return Math.floor(Math.random() * (upper - lower+1)) + lower;
}

/**
 * 移动号段
 */
const mobilePrefixArray = new Array(
    "139","138","137","136","134","135","147","150","151","152","157","158","159","172","178","182","183","184","187","188","195","197","198"
);
    
/**
 * 联通号段
 */
 const unicomPrefixArray = new Array(
    "130","131","132","140","145","146","155","156","185","186","175","176","196"
);
 
 /**
  * 电信号段
  */
const telecomPrefixArray = new Array(
    "133","149","153","177","173","180","181","189","191","193","199"
);
 
/**
 * 运营商号段集合
 */
const prefixNumberList =[].concat(
    mobilePrefixArray,unicomPrefixArray,telecomPrefixArray
);

export let generate = (size) => {
    let phones = new Array(size)
    .fill()
    .map(() => {
        var i = random(0, prefixNumberList.length-1);
        var prefix = prefixNumberList[i];
        for (var j = 0; j < 8; j++) {
            prefix = prefix + Math.floor(Math.random() * 10);
        }
        return prefix;
    });
    return phones;
}